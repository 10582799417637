.py-new-temp-container {
    background: linear-gradient(145deg, #f6f8ff, #ffffff);
    border-radius: 8px;
    padding: 24px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
    max-width: 1000px;
    /* margin: 20px auto; */
    font-family: Arial, sans-serif;
  }
  
  .py-new-temp-header {
    margin-bottom: 16px;
  }
  
  .py-new-temp-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
  }
  
  .py-new-temp-name {
    font-size: 14px;
    color: #333;
  }
  
  .py-new-temp-salary {
    font-size: 14px;
    color: #333;
  }
  
  .py-new-temp-fixed-pay {
    text-align: center;
    font-size: 12px;
    color: #666;
    padding: 4px;
    margin-bottom: 16px;
  }
  
  .py-new-temp-table {
    width: 100%;
  }
  
  .py-new-temp-table-header {
    display: grid;
    grid-template-columns: 2fr 2fr 1fr 1fr;
    background: #f8faff;
    padding: 8px 16px;
    font-size: 12px;
    color: #333;
    font-weight: 600;
    border-radius: 4px;
    margin-bottom: 8px;
  }
  
  .py-new-temp-row {
    display: grid;
    grid-template-columns: 2fr 2fr 1fr 1fr;
    padding: 8px 16px;
    font-size: 13px;
    color: #444;
    position: relative;
  }
  
  .py-new-temp-row:not(:last-child) {
    margin-bottom: 4px;
  }
  
  .py-new-temp-row-content {
    display: contents;
  }
  
  .py-new-temp-dotted-line {
    position: absolute;
    left: 16px;
    right: 16px;
    bottom: -2px;
    border-bottom: 1px dotted #ccc;
  }
  
  .py-new-temp-component {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .py-new-temp-component.blue {
    color: #2563eb;
  }
  
  .py-new-temp-calculation {
    display: flex;
    align-items: center;
    gap: 4px;
  }
  
  .py-new-temp-value {
    text-align: right;
  }
  
  .py-new-temp-total {
    display: grid;
    grid-template-columns: 2fr 2fr 1fr 1fr;
    padding: 12px 16px;
    background: #eef2ff;
    margin-top: 16px;
    border-radius: 4px;
    font-weight: 600;
    color: #1e293b;
  }
  
  .py-new-temp-total .py-new-temp-value {
    color: #2563eb;
  }
  
  .py-new-temp-percentage {
    color: #666;
    font-size: 12px;
  }
.payroll-leave-cmnt {
    /* max-width: 1200px; */
    margin: 0 auto;
  }
  
  .payroll-leave-cmnt__form {
    background-color: #fff;
  }
  
  .payroll-leave-cmnt__summary {
    background-color: #f8f9fa !important;
  }
  
  .payroll-leave-cmnt__apply-btn,
  .payroll-leave-cmnt__cancel-btn {
    min-width: 120px;
  }
.customer-receipt-table-container{
   padding: 1%;
background-color: #fff;
/* max-height: 75vh; */
overflow-y: auto; 
}
.customer-receipt-table-container table{
    width: 100%;
    background-color: #fff;
    border-collapse: collapse;
}
.customer-receipt-table-container table tbody{
    min-height: 36px;
}
.customer-receipt-table-container thead{
    position: sticky;
    top: -6px;
    background-color: #edf2f9;
    height: 50px;
    z-index: 2;
    font-size: 0.8rem;
}
.customer-receipt-table-container tfoot {
    position: sticky;
    top: -6px;
    background-color: rgb(237, 242, 249);
    height: 50px;
    z-index: 2;
    font-size: 0.8rem;
}
.customer-receipt-table-container table tbody tr td {
    border-bottom: 1px solid black;
    height: 100%;
    text-align: center;
    min-height: 40px;
    height: 50px;
    font-size: 0.8rem;
    cursor: pointer;
}

.batch-payslip__container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    /* padding: 20px; */
  }
  
  .batch-payslip__card {
    display: flex;
    background-color: #f5f5f5;
    border-radius: 4px;
    overflow: hidden;
    min-height: 120px;
    position: relative;
  }
  
  .batch-payslip__left-border {
    width: 6px;
    background-color: #6b7ff7;
  }
  
  .batch-payslip__content {
    flex: 1;
    padding: 15px;
  }
  
  .batch-payslip__field {
    margin-bottom: 12px;
  }
  
  .batch-payslip__label {
    color: #666;
    font-size: 12px;
    display: block;
    margin-bottom: 4px;
  }
  
  .batch-payslip__value {
    font-size: 14px;
    font-weight: 500;
    color: #333;
  }
  
  .batch-payslip__period {
    display: flex;
    gap: 20px;
    font-size: 14px;
    color: #333;
  }
  
  .batch-payslip__period span {
    font-weight: 500;
  }
.new-ftr-container {
    background-color: #008080;
    color: #ffffff;
    padding: 5px ;
    font-family: Arial, sans-serif;
  }
  
  .new-ftr-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .new-ftr-page-number {
    font-weight: bold;
  }
  
  .new-ftr-contact-info {
    display: flex;
    gap: 2em;
  }
  
  .new-ftr-phone,
  .new-ftr-email,
  .new-ftr-website {
    display: flex;
    align-items: center;
  }
  
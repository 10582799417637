/* Invoice.css */

.invoice-container-temp-1 {
  width: 100%;
  /* max-width: 750px; */
  /* margin: 0 auto; */
  background-color: white;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  font-family: "Poppins", sans-serif;
  position: relative;
  font-size: 14px;
}

.main-page-head-temp {
  /* position: fixed; 
  top: 0; */
}

.invoice-header-temp-1 {
  background-color: #20b2aa;
  color: white;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo-temp-1 {
  display: flex;
  align-items: center;
}

.logo-letter-temp-1 {
  font-size: 24px;
  font-weight: bold;
  margin-right: 5px;
}

.invoice-title-temp-1 {
  font-size: 24px;
  font-weight: bold;
}

.invoice-body-temp-1 {
  padding: 1.5em;
  margin-top: 30em;
  width: 100%;
}

.invoice-details-temp-1 {
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 20px; */
  border: 1px solid;
  padding: 0.5em;
  margin-top: 10em;
  width: 100%;
  height: 8.5em;
}
.invoice-details-temp-1 p,
.invoice-details-temp-1 h2 {
  margin: 0;
}
.invoice-to-temp-1,
.invoice-info-temp-1 {
  flex-basis: 48%;
  font-size: 14px;
}
.invoice-info-temp-1 p strong {
  width: 10em;
  display: inline-block;
  font-weight: 600;
}

.invoice-to-temp-1 h2,
.invoice-info-temp-1 h2 {
  font-size: 18px;
  /* margin-bottom: 10px; */
}

.invoice-table-temp-1 {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
  /* padding: 1em; */
}
.striped-table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.invoice-table-temp-1 th,
.invoice-table-temp-1 td {
  /* border: 1px solid #ddd; */
  padding: 5px;
  text-align: left;
  /* width: 1em; */
}
.invoice-table-temp-1 th {
  border: 1px solid #06508c;
}
.invoice-table-temp-1 td {
  border-left: 1px solid #000000;
  border-right: 1px solid #000000;
}
.invoice-table-btm-temp-1 td {
  border-bottom: 1px solid #000000;
}

.invoice-table-temp-1 th {
  background-color: #06508c;
  color: white;
  font-size: small;
}

.table-tt-temp-1 td {
  border: 1px solid #ddd;
  background-color: #eaeef9;
  font-weight: 600;
}

/* .invoice-footer-temp-1 {
    background-color: #000080;
    color: white;
    padding: 10px;
    text-align: center;
    margin-top: 20px;
  } */

.no-data-bt-temp-1 td {
  text-align: center !important;
}

.dynamic-ctn-temp-1 {
  margin-top: 1em;
  display: flex;
  justify-content: space-between;
}
.dynamic-ctn-temp-1 > div {
  flex: 1;
}
.ttls-temp-1 {
  display: flex;
  gap: 0.5em;
  flex-direction: column;
}
.ttls-temp-1 > div {
  display: flex;
  justify-content: space-between;
  padding: 0.5em;
  font-weight: 600;
}

.tt-1-temp-1 {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-color: #e9e9e9;
}
.flx-btwn-temp1 {
  display: flex;
  justify-content: space-between;
}
.tt-2-temp-1 {
  background-color: #4d689a;
  color: white;
}
.ol-lst-temp-1 {
  list-style: none;
  margin: 0.5em 0;
  padding: 0;
}
.ol-lst-temp-1 span {
  font-size: 12px;
  font-weight: 500;
}

.foot-detail-cntr-temp-1 {
  display: flex;
  justify-content: space-between;
}
.wrd-show-span-temp-1 {
  font-weight: 500;
}
/* footer */
.invoice-footer-temp-1 {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #ddd;
  background-color: #008080;
  padding-top: 10px;
  font-size: 12px;
  color: white;
  padding: 1em 2em;
  align-items: center;
  height: 2em;
}
.footer-contact-temp-1 {
  display: flex;
  justify-content: space-between;
  flex-basis: 60%;
  position: absolute;
  right: 5%;
  gap: 1em;
}
.footer-contact-temp-1 p {
  display: flex;
  gap: 1em;
  align-items: center;
}

/* header  */
.eygt-invoice-header {
  display: flex;
  width: 100%;
}

.eygt-logo-section {
  background-color: #20b2aa;
  flex-grow: 1;
  width: 66.67%;
  padding: 1rem;
  display: flex;
  align-items: center;
}

.eygt-logo {
  font-size: 2.25rem;
  font-weight: bold;
}

.eygt-logo-e,
.eygt-logo-y {
  color: #ffff00;
}

.eygt-logo-e2 {
  color: #000000;
}

.eygt-quotation {
  color: white;
  font-size: 1.25rem;
  margin-left: auto;
}

.eygt-invoice-info {
  background-color: #00008b;
  width: 33.33%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.eygt-invoice-title {
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.eygt-company-details {
  color: white;
}

.eygt-company-details p {
  margin: 0.25rem 0;
}

/* ----- */
.invoice-header-cntr-temp-1 {
  /* position: fixed; */
  width: 100%;
  /* left: 0;
  top: 0;  */
}
.header-container-temp-1 {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  background: white;
  /* border-radius: 10px; */
  overflow: hidden;

  /* height: 28em; */
  position: relative;
}

.header-left-temp-1 {
  height: 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #008080;
  /* padding: 1em; */
  /* position: relative; */
  position: absolute;
  left: -45px;
  width: 55%;
  /* bottom: 10px; */
  top: 10px;
  transform: skew(-30deg);
}

.header-logo-temp-1 {
  height: 4.9em;
  margin-right: 10px;
  transform: skew(30deg);
}

.header-quotation-temp-1 {
  font-size: 20px;
  color: white;
  font-weight: bold;
  transform: skew(30deg);
  background: #008080;
  /* padding: 10px 20px; */
}
.temp-company-name-temp-1 {
  font-weight: 600;
}

.fixed-detail-head-temp-1 {
  padding: 0 1.5em;
  display: flex;
  justify-content: center;
  width: 100%;
}
.header-right-temp-1 {
  height: 19.7%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #004080;
  /* padding: 20px 40px; */
  right: -40px;
  position: absolute;
  top: 55px;
  right: -29px;
  bottom: 10px;
  transform: skew(-30deg);
  width: 47%;
  height: 30%;
}
.header-right-inv-temp-1 {
  right: -40px;
  position: absolute;
  top: 10px;
  right: -100px;
  bottom: 10px;
  transform: skew(-30deg);
  background: #008080;
  padding-left: 3%;
  width: 46.5%;
  height: 15%;
}

.header-invoice-temp-1 {
  font-size: 1.5em;
  color: white;
  font-weight: bold;
  margin-bottom: 10px;
  /* width: 14em; */
  transform: skew(30deg);
  padding: 2%;
}

.header-company-details-temp-1 {
  padding-left: 7.5em;
  transform: skew(30deg);
  margin: 0;
  color: white;
  line-height: 1.5;
  font-size: 14px;
}
.header-company-details-temp-1 p {
  margin: 0;
}
/* temp - two  */

.invoice-container-temp-2 {
  max-width: 1000px;
  /* height: 100vh; */
  margin: 0 auto;
  /* padding: 20px; */
  font-family: Arial, sans-serif;
  /* border: 1px solid #ddd; */
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  font-size: 14px;
}
.print-header-temp-2-wrapper {
  padding: 1.6em;
}
.invoice-header-temp-2 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
  height: 7em;
}

.logo-temp-2 img {
  height: 50px;
  height: 50px;
  object-fit: contain;
}

.logo-letter-temp-2 {
  display: inline-block;
  width: 40px;
  text-align: center;
}

.green-temp-2 {
  color: #8cc63f;
}
.black-temp-2 {
  color: #000;
}

.company-info-temp-2 {
  text-align: right;
}

.company-info-temp-2 h2 {
  margin: 0;
  color: #333;
}

.company-info-temp-2 p {
  margin: 5px 0;
  color: #666;
}

.quotation-title-temp-2 {
  text-align: center;
  margin: 7px 0;
  color: #333;
  background-color: #e3e3e3;
  padding: 0.2em 0;
}
.rtlb-temp-2 {
  border: 1px solid black;
}
.tb-temp-2 p {
  display: flex;
  gap: 1em;
  align-items: center;
}
.tb-temp-2 {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}
.invoice-details-temp-2 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.invoice-details-temp-2 P , .invoice-details-temp-2 h3{
  margin: 0.3em;
}

.invoice-details-temp-2 P > strong{
  width: 8em;
  display: inline-block;
}

.invoice-to-temp-2,
.contact-info-temp-2,
.quote-info-temp-2 {
  flex-basis: 60%;
  padding: 1em;

}

.invoice-title-temp-2 {
  height: 1px;
  background-color: #000;
}

.invoice-table-temp-2 {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  font-size: 14px;
}

.invoice-table-temp-2 th,
.invoice-table-temp-2 td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
 
}

.invoice-table-temp-2 th {
  background-color: #8cc63f;
  color: white;
}

.terms-conditions-temp-2 {
  margin-bottom: 20px;
  font-size: 11px;
  text-transform: capitalize;
}

.terms-conditions-temp-2 ol {
  padding-left: 20px;
}

.authorized-signatory-temp-2 {
  text-align: right;
  margin-bottom: 20px;
  flex-basis: 40%;
}
.authorized-signatory-temp-2 p {
  text-align: right;
  margin-bottom: 20px;
  margin: 2em 0;
}

.signature-line-temp-2 {
  width: 200px;
  height: 1px;
  background-color: #000;
  margin-left: auto;
}

.invoice-footer-temp-2 {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #ddd;
  padding-top: 10px;
  font-size: 12px;
  color: #666;
}

/* tem -3  */
.bilingual-invoice-container-temp-3 {
  width: 100%;
  /* max-width: 800px; */
  margin: 0 auto;
  /* padding: 20px; */
  font-family: Arial, sans-serif;
  /* display: flex;
  justify-content: center;
  flex-direction: column; */
  /* border: 1px solid #ddd; */
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
}

.header-wrapper-temp-3{
  height: 25em;
}
.invoice-header-temp-3 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
}

.logo-section-temp-3 {
  display: flex;
  flex-direction: column;
}

.logo-temp-3 {
  font-size: 36px;
  font-weight: bold;
}

.logo-letter-temp-3 {
  display: inline-block;
  width: 40px;
  text-align: center;
}

.green-temp-3 {
  color: #8cc63f;
}
.black-temp-3 {
  color: #000;
}

.order-id-temp-3 {
  font-size: 12px;
  margin-top: 5px;
}

.invoice-title-temp-3 {
  text-align: right;
}

.invoice-title-temp-3 h1 {
  margin: 0;
}

.invoice-details-temp-3 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.invoice-to-temp-3,
.invoice-to-arabic-temp-3 {
  flex-basis: 48%;
}

.invoice-to-arabic-temp-3 {
  text-align: right;
}

.invoice-table-temp-3 {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.invoice-table-temp-3 th,
.invoice-table-temp-3 td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.invoice-table-temp-3 th {
  background-color: #000;
  color: white;
}

.authorized-signatory-temp-3 {
  text-align: right;
  margin-bottom: 20px;
}

.signature-line-temp-3 {
  width: 200px;
  height: 1px;
  background-color: #000;
  margin-left: auto;
}

.terms-conditions-temp-3,
.terms-conditions-arabic-temp-3 {
  margin-bottom: 20px;
  font-size: 12px;
}

.terms-conditions-arabic-temp-3 {
  text-align: right;
}

.invoice-footer-temp-3 {
  text-align: center;
  margin-top: 20px;
  font-weight: bold;
}

/* Print styles */
@media print {
  body {
    margin: 0;
    padding: 0;
  }

  .invoice-container-temp-1 {
    box-shadow: none;
  }

  /* .invoice-header-temp-1 {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  } */

  .invoice-table-temp-1 thead {
    display: table-header-group;
  }

  .invoice-footer-temp-1 {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .invoice-body-temp-1 {
    margin-top: 30px;
    margin-bottom: 50px;
  }

  @page {
    margin: 0;
  }
}

/* temp - 2 */
/* @media print {
  .invoice-container-temp-2 {
    box-shadow: none;
    border: none;
  }

  @page {
    size: A4;
    margin: 0;
  }

  body {
    margin: 1.6cm;
  }
} */

/* temp -3  */
/* @media print {
  .bilingual-invoice-container-temp-3 {
    box-shadow: none;
    border: none;
  }

  @page {
    size: A4;
    margin: 0;
  }

  body {
    margin: 1.6cm;
  }
} */

/* @media print {
  .page-container {
    page-break-after: always;
  }
} */

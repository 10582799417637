.main-flow-chart-container {
  width: 100%;
}

.flowchart {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 30em;
}

.node {
  display: flex;
  align-items: center;
  margin: 10px;
  padding: 10px;
  border: 2px solid var(--border-color, #cbcdd8);
  border-radius: 5px;
  position: relative; /* Added */
  justify-content: center;
  gap: 0.5em;
  font-weight: 500;
  color: var(--border-color, #000000);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.node:hover {
  background-color: #f0f0f0;
  border-color: #999999;
  color: #333333;
}

.flow-row-2 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flow-row-2 > div {
  flex: 0 0 20%;
}

.flow-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 2%;
  align-items: center;
}
.flow-row > div {
  flex: 1;
}

.purchase-main-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.divider-line {
  height: 2px;
  width: 80%;
  background-color: #cbcdd8;
  position: relative;
  margin-bottom: 1em;
}

.divider-line::before,
.divider-line::after,
.line-bottom::before,
.line-left::before {
  content: "";
  position: absolute;
  border-color: #cbcdd8;
}

.divider-line::before,
.divider-line::after {
  top: 0;
  left: 0%;
  border-left: 2px solid #cbcdd8;
  height: 1.6em;
  transform: translateX(-50%);
}
.divider-line::after {
  left: 100%;
}

.line-bottom::before {
  top: 100%;
  left: 50%;
  border-left: 2px solid var(--line-color, #cbcdd8);
  height: 30%;
  transform: translateX(-50%);
}

.line-left::before {
  top: 50%;
  left: -20px;
  border-top: 2px solid var(--arrow-color, #cbcdd8);
  width: 20px;
  transform: translateY(-50%);
}

.arrow-container {
  position: absolute;
}

.arrow {
  position: absolute;
  width: 0;
  height: 0;
  border: solid black;
}

.left-arrow {
  border-width: 5px 5px 5px 0;
  border-color: transparent var(--arrow-color, #cbcdd8) transparent transparent;
  left: -10px;
  top: 50%;
  transform: translateY(-50%);
}

.right-arrow {
  border-width: 5px 0 5px 5px;
  border-color: transparent transparent transparent var(--arrow-color, #cbcdd8);
  right: -10px;
  top: 50%;
  transform: translateY(-50%);
}

.top-arrow {
  border-width: 0 5px 5px 5px;
  border-color: black transparent transparent transparent;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.bottom-arrow {
  border-width: 5px 5px 0 5px;
  border-color: var(--arrow-color, #cbcdd8) transparent transparent transparent;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.arrow-split {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.arrow-split .split-1 {
  position: relative;
  margin: 0 10%;
}
.pr-arrow-left {
}

.pr-wpo-arrow {
  bottom: -19px;
}
.arr-end-left {
  left: -25px;
}

.flow-row-right {
  display: flex;
  gap: 0.5em;
  justify-content: space-between;
}
.flow-row-right > div {
  flex: 1;
}

.arr-payment {
  left: 105%;
}
.line-right::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 111%;
  border-top: 2px solid #000;
  width: 100%;
  transform: translateY(-50%);
}
.flw-main-head {
  font-weight: 600;
  text-transform: uppercase;
}

.flw-style-1 {
  border: 2px solid var(--border-color, #cbcdd8);
}
.flw-style-2 {
  border: 2px solid var(--border-color, #87bd47);
  min-width: 12em;
}
.flw-style-3 {
  border: 2px solid var(--border-color, #87bd47);
}
.flw-style-3_1 {
  min-width: 12.3em;
}
.flw-style-4 {
  background-color: #fbe6e5;
}

.customer-invoice-name-container{
    display: flex;
    width: 40%;
}
.customer-invoice-radio-container{
    display: flex;
    width: 60%;
    justify-content: space-around;
}
.customer-invoice-radio-single-container{
   width: 30%;
   width: 30%;
    display: flex;
    align-items: center;
}
.customer-invoice-radio-single-container input[type="radio"]{
    accent-color: #000;
    width: 18px;
    height: 74%;
    cursor: pointer;
}
.customer-invoice-radio-single-container label{
    font-size: 0.8rem;
    margin: 3% 0 0 0;
    cursor: pointer;
    font-weight: 600;
}

.advance-payments-add-btn{
    background-color: #ccc9ff;
    font-size: 0.7rem;
    border: 1px solid blue;
    color: blue;
    border-radius: 2px;
    cursor: pointer;
    margin: 3px 0;
}
.advance-payments-add-btn:hover{
    background-color: #bab7ff;
    color: #fff;
}

.payment-info-amounts-ctn{
    width: calc(25% - 8px);
    margin-left: auto;
    max-width: 275px;
    margin-right: 8px;
}
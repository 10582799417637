.new-hdr-invoice-header {
    display: flex;
    width: 100%;
    height: 120px;
    overflow: hidden;
    font-family: Arial, sans-serif;
  }
  
  .new-hdr-left-section {
    flex: 1;
    background-color: #008080;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    padding: 0 20px;
    clip-path: polygon(0 0, 100% 0, 73% 100%, 0 100%);
    z-index: 2;
  }
  .new-hdr-company-logo{
    height: 50px;
    width: 50px;
  }
  .new-hdr-company-name {
    color: white;
    font-size: 24px;
    font-weight: bold;
  }
  
  .new-hdr-right-section {
    flex: 2;
    position: relative;
    display: flex;
    flex-direction: column;
  }
  
  .new-hdr-invoice-title {
    background-color: #008080;
    color: white;
    padding: 10px 40px;
    font-size: 20px;
    font-weight: bold;
    clip-path: polygon(7% 0, 100% 0, 100% 100%, 0 100%);
    z-index: 1;
    position: absolute;
    top: 0;
    left: 20%;
    right: 0;
  }
  
  .new-hdr-company-details {
    background-color: #004080;
    color: white;
    padding: 40px 100px 0px;
    clip-path: polygon(9% 0, 100% 0, 100% 100%, 0 100%);
    position: absolute;
    top: 42%;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  
  .new-hdr-company-details p {
    margin: 1px 0;
    font-size: 13px;
  }
  
  .table-head2-new th{
    background-color:#eef1f9 ;
    color: black ;
    font-size: 13px;
    font-weight: 600;
  }
  .table-head1-new th {
    background-color:#004080 ;
    color: #ffff;

  }
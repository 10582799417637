.emp-dashboard-apply-leave-btn {
  margin: 1% 1% 1% 0 !important;
}
.employee-dashborad-top-container {
  display: flex;
  justify-content: flex-end;
}
.employee-dashboard-date-time-picker {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 7px;
}
.employee-dashboard-date-picker {
  background-color: #ff5050;
  display: flex;
  height: 30px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 1% 6px;
}
.employee-dashboard-date-picker .calendar-icon {
  color: #fff;
  font-size: 1.3rem;
  font-weight: 500;
  position: absolute;
  left: 4px;
}
.employee-dashboard-date-picker input {
  border: none;
  background-color: #ff5050;
  color: #fff;
  padding: 0 2%;
  cursor: pointer;
  flex-direction: row-reverse;
  width: 98%;
}

.date-picker-hr {
  border-right: 1px solid #fff !important;
  border: none;
  height: 20px;
  padding: 0 2%;
}

.employee-dashboard-time-picker {
  background-color: #2611e2;
  display: flex;
  height: 30px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 1% 6px;
}
.employee-dashboard-time-picker input {
  border: none;
  background-color: #2611e2;
  color: #fff;
  padding: 0 2%;
  cursor: pointer;
  flex-direction: row-reverse;
  width: 98%;
}
/* .employee-dashboard-date-picker input[type="date"] {
  background-color: #ff5050;
  border: none;
  height: 40px;
  width: 45%;
  flex-direction: row-reverse;
  color: #fff;
  border-radius: 4px;
  margin: 1%;
  font-size: 1rem;
}
.employee-dashboard-date-picker input[type="time"] {
  background-color: #2611e2;
  border: none;
  height: 40px;
  width: 45%;
  flex-direction: row-reverse;
  color: #fff;
  border-radius: 4px;
  margin: 1%;
  font-size: 1rem;
} */

.employee-dashboard-card-container {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
}
.employee-dashboard-card-container >:nth-child(3){
margin-right: 0;
}
.employee-dashboard-card {
  background-color: #fff;
  padding: 2% 3%;
  width: 32%;
  margin: 1% 2% 1% 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
}
.employee-dashboard-card-icon-container {
  border-radius: 50%;
  width: 65px;
  height: 65px;
  background-color: #0020f1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.employee-dashboard-card-text-container {
  width: 47%;
}
.employee-dashboard-card-text-container h5 {
  margin: 1%;
}
.employee-dashboard-card-text-container p {
  margin: 0;
  font-size: 1.1rem;
  font-weight: 500;
}
.employee-dashboard-card-arrow-icon-container {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: #0020f1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.employee-dashboard-graph-calender-container {
  display: flex;
  justify-content: space-between;
}
.employee-dashboard-graph-container {
  width: 68%;
  background-color: #fff;
  padding: 2%;
  height: 55vh;
  max-height: 55vh;
  border-radius: 4px;
}
.employee-dashboard-calender-container {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 55vh;
}
.employee-dashboard-calender-top-container {
  background-color: #fff;
  height: 8vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.employee-dashboard-calender-top-container h4 {
  margin: 0;
}
.react-calendar {
  border: none !important;
  width: inherit !important;
  height: auto;
  padding: 0 2%;
}
.employee-dashboard-calender-second-container {
  height: 44vh;
}
.leave-holidays-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 1% 0;
}
.leave-details-container {
  background-color: #fff;
  padding: 2%;
  width: 56%;
  display: flex;
  flex-direction: column;
}

.leave-details-top-container {
  display: flex;
  justify-content: space-between;
}
.leave-details-top-container h3 {
  margin: 1% 2%;
}
.leave-details-top-right-container {
  display: flex;
  justify-content: space-between;
  width: 60%;
}
.leave-details-top-right-child-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.leave-details-top-right-child-container h5 {
  color: #159115;
  margin: 1% 0;
}
.leave-details-top-right-child-container p {
  color: #159115;
  margin: 2% 0;
  font-size: 1.2rem;
}
.leave-details-table-container table {
  width: 98%;
  margin: 2%;
}
.leave-details-table-container table thead tr th {
  padding: 16px 4px;
  font-weight: 600;
  font-size: 0.9rem;
  text-align: left;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}
.leave-details-table-container table tbody tr td {
  text-align: left;
  font-size: 0.8rem;
  font-weight: 600;
  padding: 4px 6px;
}
.leave-details-table-container table tbody tr td.leave-table-balance {
  color: rgb(172, 169, 169);
}
.leave-details-table-container table tbody tr td.left-head {
  font-size: 0.8rem;
  font-weight: 700;
}
.dashboard-apply-leave-btn {
  align-self: center;
  margin: 1% 0 0 0 !important;
}
.holiday-container {
  background-color: #fff;
  padding: 2% 4% 0 4%;
  width: 43%;
}
.holiday-container h3 {
  margin: 1%;
}
.holiday-content-container {
  margin: 4% 0;
}
.holiday-content-container-single-container {
  display: flex;
  margin: 2% 0;
}
.holiday-single-container-box {
  width: 55px;
  height: 55px;
  background-color: #e0e0ff;
  color: #3e16ff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.holiday-single-container-box .date {
  margin: 0;
  font-size: 1.1rem;
  font-weight: 600;
}
.holiday-single-container-box .month {
  margin: 0;
  font-size: 0.8rem;
  font-weight: 500;
}
.holiday-single-container-middle-text {
  display: flex;
  flex-direction: column;
  width: 58%;
  margin: 0 0 0 8%;
  justify-content: center;
}
.holiday-single-container-middle-text h4 {
  margin: 0;
  font-size: 0.9rem;
}
.holiday-single-container-middle-text p {
  margin: 0;
  font-size: 0.8rem;
}
.holiday-single-container-last-text {
  display: flex;
  justify-content: center;
  align-items: center;
}
.holiday-single-container-last-text p {
  margin: 0;
  font-size: 0.8rem;
  font-size: 0.7rem;
  font-weight: 600;
}

.employee-dashboard-card-icon-container-circle{
  border: 1px solid #fff;
  border-radius: 50%;
  width: 50%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}


/* / EMpDashboardTotalCommission CSS /
/ EMpDashboardTotalCommission CSS /
/ EMpDashboardTotalCommission CSS / */

.employee-dashboard-total-commission-container{
  
  display: flex;
  width: 100%;
  justify-content: space-between;

}
.employee-dashboard-total-commission-container a{
  text-decoration: none;
  width: 16%;
  border: none;
}
.employee-dashboard-total-commission-container a:active{
  border: none;
}
.employee-dashboard-total-commission-container .total-commission{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;

}
.employee-dashboard-total-commission-container .total-commission:hover{
 
  border: 1px solid rgb(78, 163, 237);
  color: rgb(76, 163, 239);

}
.employee-dashboard-total-commission-container .total-commission i{
  font-size: 2rem;
}
.btn-font-size-less{
  font-size: 0.9rem !important;
}
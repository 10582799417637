.top-main-containerbs {
  background-color: white;
  padding: 1px;
  width: 99%;
  margin-left: 5px;
  margin-top: -29px;
}
.top-main-cont {
  background-color: white;
  padding: 2px;
  width: 99%;
  margin-left: 5px;
  margin-top: -3px;
}
.top-main-cont2 {
  background-color: white;
  padding: 2px;
  width: 99%;
  margin-left: 5px;
  margin-top: -4px;
}
.table-containerbs {
  padding-inline: 24px;
}
.table-head2 td {
  background-color: rgb(238, 241, 249);
  color: black;
  text-align: start;
  font-weight: 500;
  padding-left: 18px;
  width: 80%;
}
.table-head3 td {
  background-color: rgb(255, 255, 255);
  color: black;
  text-align: start;
  font-weight: 400;
  padding-left: 18px;
  width: 80%;
  border: none;
  outline: none;
}

.table-header-bs {
  display: flex;
  flex-direction: column;
  height: 115px;
}
.sec-main-ctn {
  background-color: white;
  display: flex;
  justify-content: space-between;
  padding: 1px 30px;
}

/* new balance sheet styles  */
.balance-sheet-main-container {
  padding: 0.5em;
  background-color: white;
  height: 90vh;
  overflow: hidden;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.header-for-balance-sheet {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.balance-sheet-action-btns {
  display: flex;
  align-items: center;
}
.balance-sheet-body {
  margin-top: 1em;
}
.balance-sheet-body .balance-sheet-body-head span {
  font-size: 1em;
  font-weight: 600;
  margin-left: 0.3em;
}
.balance-sheet-body-table-container {
  margin-top: 1em;
  max-height: 70vh;
  overflow-y: auto;
  position: relative;
}
.balance-sheet-body-table-container .bs-no-data {
  text-align: center;
  background-color: white;
  color: black;
  padding: 0.5em;
  box-shadow: 1px 3px 5px #888888;
}
.balance-sheet-body-table-container th {
  position: sticky;
  top: 0;
  z-index: 9;
}

.balance-sheet-body-table-container th,
.balance-sheet-body-table-container td {
  width: 100%;
  /* border: 1px solid rgb(255, 255, 255); */
  border-collapse: collapse;
  text-align: left;
}
.balance-sheet-body-table-container table thead :nth-child(1) {
  border-top-left-radius: 10px;
}
.balance-sheet-body-table-container table thead :nth-child(2) {
  border-top-right-radius: 10px;
}
.balance-sheet-body-table-container table thead th,
.bs-footer-total td {
  font-weight: 500;
  font-size: 0.8em !important;
  background-color: #264a95;
  color: white;
  padding: 0.5em !important;
}
.balance-sheet-body-table-container table tbody {
  position: relative;
}
.bs-sub-head {
  color: white;
  background-color: #6980bc;

  position: sticky;
  top: 2em;
}


.balance-sheet-body-table-container .bs-bold {
  font-weight: 500;
  font-size: 0.8em;
}
.bs-total-amt {
  font-weight: 500;
}
.bs-sub-data :last-child {
  text-align: end;
}
.bs-main-body {
  overflow-y: auto;
  max-height: 5em;
}
.bs-main-body .bs-txt-end {
  text-align: end;
}
.bs-footer-total td {
  text-align: end;
}
.balance-sheet-body-table-container table tbody .bs-main-body td {
  background-color: #eef1f9;
  color: black;
}
.balance-sheet-body-table-container table tbody td {
  padding: 0em 0.5em;
  font-size: 0.8em;
  font-weight: 400;
}
.bs-new-print-layout-table th{
background-color: #264a95;
color: white;
font-weight: 600;
}

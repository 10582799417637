.py-ot-new-crd-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
    gap: 24px;
    /* padding: 24px; */
    min-height: calc(100vh - 200px);
    /* background-color: #f8fafc; */
  }
  
  .py-ot-new-crd {
    background: white;
    border-radius: 12px;
    padding: 24px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
    border: 1px solid #e2e8f0;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }
  
  .py-ot-new-crd:hover {
    transform: translateY(-2px);
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  
  .py-ot-new-crd-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .py-ot-new-crd-category,
  .py-ot-new-crd-name,
  .py-ot-new-crd-amount,
  .py-ot-new-crd-account {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  
  .icon {
    color: #64748b;
  }
  
  .py-ot-new-crd-status {
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 13px;
    font-weight: 500;
    padding: 6px 12px;
    border-radius: 20px;
  }
  
  .py-ot-new-crd-status.active1 {
    background-color: #dcfce7;
    color: #166534;
  }
  
  .py-ot-new-crd-status.inactive {
    background-color: #fee2e2;
    color: #991b1b;
  }
  
  .status-icon {
    width: 16px;
    height: 16px;
  }
  
  .active1 .status-icon {
    color: #166534;
  }
  
  .inactive .status-icon {
    color: #991b1b;
  }
  
  .py-ot-new-crd-name {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #e2e8f0;
  }
  
  .py-ot-new-crd-footer {
    display: flex;
    justify-content: space-between;
  }
  
  .label {
    display: block;
    font-size: 13px;
    color: #64748b;
    margin-bottom: 4px;
  }
  
  .value {
    display: block;
    font-size: 15px;
    font-weight: 600;
    color: #1e293b;
  }
  
  .py-ot-new-crd-amount .value {
    color: #2563eb;
  }
  
  /* Hover effects */
  .py-ot-new-crd:hover .icon {
    color: #2563eb;
  }
  
  /* Custom scrollbar */
  .py-ot-new-crd-container::-webkit-scrollbar {
    width: 8px;
  }
  
  .py-ot-new-crd-container::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }
  
  .py-ot-new-crd-container::-webkit-scrollbar-thumb {
    background: #cbd5e1;
    border-radius: 4px;
  }
  
  .py-ot-new-crd-container::-webkit-scrollbar-thumb:hover {
    background: #94a3b8;
  }
  
  /* Responsive Design */
  @media screen and (max-width: 768px) {
    .py-ot-new-crd-container {
      grid-template-columns: 1fr;
      padding: 16px;
    }
  
    .py-ot-new-crd {
      padding: 20px;
    }
  
    .py-ot-new-crd-footer {
      flex-direction: column;
      gap: 16px;
    }
  }
/* import file popup style  */
.imp-comp-header-cntr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5em;
  border-bottom: 1px dashed black;
}
.imp-comp-header{
    color: #ff7936;
    font-weight: 600;
    line-height: 2.5em;
}
.imp-comp-btn-submit{
    background-color: #0011ff;
    padding: 0.5em;
    color: white;
    border: transparent;
    font-weight: 600;
    min-width: 7em;
    cursor: pointer;

}

/* FileUpload.css */
.file-upload {
    display: flex;
    align-items: center;
    border: 1px dashed #000;
    padding: 1px;
    width: 15em;
  }
  
  .file-upload__input {
    flex-grow: 1;
  }
  
  .file-upload__label {
    cursor: pointer;
  }
  
  .file-upload__filename {
    max-width: 200px; 
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .file-upload__icon {
    width: 50px;
    /* height: 50px; */
    background-color: #0011ff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: white;
    padding: 0.5em;
  }
  
  .file-upload__icon img {
    width: 30px;
    height: 30px;
    filter: invert(1);
  }

  .imp-action-cntr{
    display: flex;
    /* align-items: center; */
    gap: 1em;
  }
/* list page  */
.brc-main-container {
  background-color: transparent;
  padding: 1em;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.brc-main-head {
  background-color: rgb(255, 255, 255);
  padding: 1em;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.brc-list {
  margin-top: 0.5em;
  background-color: rgb(255, 255, 255);
  padding: 1em;
}
.brc-head-right {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
}
.brc-head-create-btn {
  background-color: #8500b3;
  padding: 1em;
  border-radius: 5px;
  border: transparent;
  color: white;
  min-width: 8em;
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: background-color 0.3s ease, transform 0.3s ease,
    box-shadow 0.2s ease;
}

.brc-head-create-btn:hover {
  background-color: #a000d4;
  transform: scale(1.05);
}

/* Click effect */
.brc-head-create-btn:active {
  transform: scale(0.95);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

/* Shining effect */
.brc-head-create-btn::before {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 300%;
  height: 300%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  transform: rotate(45deg);
  transition: all 0.3s;
}

.brc-head-create-btn:hover::before {
  left: 180%;
  top: 180%;
  transition: all 0.3s;
}

.brc-pagination {
  text-align: end;
}
.brc-main-list {
}

/* Table Styles */
.brc-table-container {
  max-height: 41em; /* Set the max height as needed */
  overflow-y: auto;
  /* border: 1px solid #ddd;     */
  margin: 20px 0;
}

/* Table Styles */
.brc-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 18px;
  text-align: left;
}

/* Sticky header */
.brc-thead {
  background-color: #eef1fa;
  position: sticky;
  top: 0;
  z-index: 1;
}

/* Table Row */
.brc-tr {
  border-bottom: 1px solid #dddddd;
  font-size: 0.8rem;
}

/* Table Header and Cell */
.brc-th,
.brc-td {
  padding: 12px 15px;
  text-align: center;
}

/* Header Styles */
.brc-th {
  background-color: #eef1fa;
  font-weight: bold;
  color: #333;
  font-size:0.8rem;
}

.brc-status-btn {
  border: transparent;
  padding: 3px;
  min-width: 5em;
  width: 40%;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: 600;
}

/* Status Cell Styles */
.brc-status-draft {
  background-color: #dbeafe; 
  color: #1e3a8a; 
  text-align: center;
}

.brc-status-posted {
  background-color: #d1fae5; 
  color: #065f46; 
  text-align: center;
}

.brc-status-canceled {
  background-color: #fee2e2; 
  color: #b91c1c; 
  text-align: center;
}

.brc-status-in-progress {
  background-color: #fef3c7; 
  color: #92400e; 
  text-align: center;
}

/* create page  */

.crbr-main-container {
  padding: 0.5em;
  background-color: transparent;
}
.crbr-cmn {
  background-color: white;
  margin-top: 0.5em;
  padding: 0.5em;
}
.crbr-main-head span {
  /* margin-left: 1.5em; */
}
.crbr-main-head {
  font-weight: 600;
}
.crbr-action-head {
  padding:0;
}
/* ---------- */
.crbr-container {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* padding: 20px; */
  position: relative;
}

.crbr-input-group {
  margin-top: 2em;
  display: flex;
  gap: 0.5em;
  padding: 1em ;
  height: 6em;
}

.crbr-label {
  font-weight: bold;
  margin-bottom: 5px;
}

.crbr-input,
.crbr-select {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.crbr-button-group {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  /* padding: 1em; */
}
.crcb-cmn-btn {
  padding: 0.5em;
  margin: 0 5px;
  border: none;
  border-radius: 3px;
  background-color: var(--background-cl,#ff8104) ;
  color: #ffffff;
  cursor: pointer;
  min-width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
  height: 2.5em;
  font-weight: 600;
}
.crbr-wrapper{
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #bdbdbd;
  padding: 0.5em 0;

}
.crbr-bottom-list ul{
list-style: none;
padding-left: 0;
}
.crbr-bottom-list ul li {
  display: flex;
  justify-content: space-between;
  padding: 0.5em 1.5em;
  margin:0.5em 0;;
  background-color: #c3cfff;
  font-weight: 600;
}
.crbr-cmn.crbr-bottom-list{
  padding: 0;
  background-color: transparent;
}


.crbr-badge {
  position: absolute;
  right: 0;
  top: 0;
}
.crbr-button i {
  /* font-size: 1.5em; */
}

.crbr-button {
  /* width: 1.8em;
   height: 1.8em; */
}
.crbr-edit-btn{
  min-width: 2em;
  min-height: 2em;
}


.crbr-primary {
  background-color: #1c04ff;
  color: #fff;
  min-width: 6em;
}

.crbr-secondary {
  background-color: #6c757d;
  color: #fff;
}

/* --- */
.crb-back-btn {
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5em;
}

/* common table  */

.crbr-show-table{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1em ;
}
.crbr-table-container {
  overflow: auto;
  max-height: 10em; 
  min-height: 200px; 
  position: relative;
}
.crbr-main-header{
  font-weight: 600;
  padding: 1em;
}

.crbr-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.crbr-none{
  width: 95%;
}

.crbr-table th,
.crbr-table td {
  padding: 5px;
  border: 1px solid #ddd;
  font-size: 13px;
}

.crbr-table th {
  background-color: #f2f2f2;
  position: sticky;
  top: 0;
  z-index: 9;
  border-top: 0 solid black;
}

.crbr-status {
  display: flex;
  justify-content: center;
 
}
.crbr-status >div{
  padding: 5px 10px;
  border-radius: 4px;
  color: #fff;
  min-width: 8em;
  text-align: center;
}

.crbr-status>div.pending {
  background-color: orange;
}
.crbr-status>div.completed{
  background-color: green;
}
.crbr-status>div.reconciled{
  background-color: green;
}


/* .crbr-show-table {
  position: relative;
} */
.crbr-table tbody tr {
  position: relative;
}
.crbr-table-input{
  width: 100%;
  /* border-top: transparent;
  border-left: transparent;
  border-right: transparent; */
  border: transparent;
}
.crbr-pay-button-container {
  padding: 0.5em;
  position: absolute;
  transform: translate(10%, -95%);
  min-width: 1em;
  right: -4%;
}

.crbr-pay-button {
  width: 100%;
  /* padding: 10px 20px; */
  background-color: blue;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.crbr-pay-button:hover {
  background-color: darkblue;
}
.brc-table-container::-webkit-scrollbar {
  width: 4px;
  /* background-color: #d3e3ec; */
  background-color: #f1f1f1;
}
.brc-table-container::-webkit-scrollbar-thumb {
  /* background-color: #a7c6d8; */
  background-color: #c1c1c1;
}
.account-dashboard-container {
  padding: 0.5em;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.account-dashboard-wrapper {
  padding: 0.5em;
  height: 90vh;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}
.act-dash-head {
  display: flex;
  gap: 1em;
}
.act-dash-dtls {
  margin-top: 1em;
  display: flex;
  gap: 1em;
  flex-wrap: wrap;
}
.act-dash-dtls > div {
  flex: 1;
}
